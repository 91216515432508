<template>
  <div class="box pb-6 pt-1" id="login">
    <div class="field">
      <label class="label">Username</label>
      <div class="control has-icons-left">
        <input
          class="input"
          type="username"
          id="username_input"
          placeholder="username"
          v-model="username"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Password</label>
      <div class="control has-icons-left">
        <input
          class="input"
          type="password"
          id="password_input"
          placeholder="********"
          v-model="password"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
      </div>
    </div>
    <div class="columns mt-2">
      <div class="column"></div>
      <div class="column">
        <button class="button purple-button is-pulled-right" @click="login">
          Sign in
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { store, logIn } from "../store";
import { useRouter } from "vue-router";

const router = useRouter();

const username = ref([]);
const password = ref([]);

async function login() {
  try {
    const response = await axios.post(`api/authentication/login`, {
      username: username.value,
      password: password.value,
    });
    logIn(response.data);
    if (store.role == "admin") {
      router.push("/");
    } else if (store.role == "employee") {
      router.push("/employee-view");
    } else {
      router.push("/");
    }
  } catch (err) {
    console.log(err);
    alert("bad user/pass");
    username.value = "";
    password.value = "";
  }
}
</script>

<style>
#login {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
</style>
