import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { logIn, store } from "@/store";
import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/analysis",
    name: "analysis",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "analysis" */ "../views/ReorderView.vue"),
  },
  {
    path: "/purchase_order",
    name: "purchase_order",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "purchase_order" */ "../views/PurchaseOrderView.vue"
      ),
  },
  {
    path: "/purchase_order_history",
    name: "purchase_order_history",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "purchase_order_history" */ "../views/PurchaseOrderHistoricalView.vue"
      ),
  },
  {
    path: "/accounts",
    name: "accounts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "accounts" */ "../views/AccountsView.vue"),
  },
  {
    path: "/vendor_settings",
    name: "vendor_settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "vendor_settings" */ "../views/VendorSettingsView.vue"
      ),
  },
  {
    path: "/discontinued",
    name: "discontinued",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "discontinued" */ "../views/DiscontinuedView.vue"
      ),
  },
  {
    path: "/backorders",
    name: "backorders",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "backorders" */ "../views/BackordersView.vue"
      ),
  },
  {
    path: "/lowsale",
    name: "lowsale",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "low_sales" */ "../views/LowSalesView.vue"),
  },
  {
    path: "/analysisv2",
    name: "analysisv2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "low_sales" */ "../views/AllItemsDemo.vue"),
  },
  {
    path: "/employee-view",
    name: "employee-view",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "low_sales" */ "../views/EmployeeReadOnlyView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//redirect to home page if not logged in
router.beforeEach(async (to, from) => {
  try {
    const response = await axios.post("api/authentication/checkLogin");
    logIn(response.data);
  } catch {
    console.log("Auth check failed");
  }

  if (!store.isLoggedIn && to.name != "home") {
    return { name: "home" };
  }
});

export default router;
